import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import Masthead from "../../components/Masthead";
import HeadingAnchor from "../../components/HeadingAnchor";
import Hero from "../../components/Hero";
import Layout from "../../components/Layout";
import {
  additionalSupportList,
  compensationList,
  ideas42SupportList,
  Content,
  supportList,
} from "../../data/what-we-provide";
import FooterBackButton from "../../components/FooterBackButton";

import "../../styles/what-we-provide.scss";

const ListItem = ({ name, description }: Content) => (
  <li data-testid="listItem" className="font-size-2">
    <strong>{`${name} `}</strong>
    {description}
  </li>
);

const List = ({
  resourceList,
}: {
  resourceList: Content[];
}): React.ReactElement => (
  <ul>
    {resourceList.map((comp) => (
      <ListItem
        key={comp.name}
        name={comp.name}
        description={comp.description}
      />
    ))}
  </ul>
);

const DetailsCard = () => (
  <aside className="card grid--max-2 provide__details-card">
    <div className="card__intro">
      <p>
        <strong className="card__title">
          If you are selected to join, we will…{" "}
        </strong>
      </p>
    </div>
    <section className="card__body">
      <ul>
        <li>
          Pay you a <strong>salary</strong> of $80k per year
        </li>
        <li>
          Provide <strong>benefits</strong> for 18 months
        </li>
        <li>
          Surround you with a dedicated <strong>team</strong> of business
          builders and behavioral scientists to help bring your vision to life
        </li>
      </ul>
    </section>
  </aside>
);

export const WhatWeProvidePage: React.FC<DefaultPageProps> = ({
  location,
  data,
}) => {
  const pageTitle = "ideas42 Venture Studio - What We Provide";

  return (
    <Layout
      footerChildren={<FooterBackButton text="Back to Studio" to="/studio" />}
    >
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl + location.pathname}
        />
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <Masthead>
        <Hero
          className="grid"
          tag="The support"
          title="We’re with you"
          body="Starting a business is risky, and for many individuals without a personal financial safety net, or access to a network of individuals with significant capital to fund their venture, the risk is simply too high. We view this as an unacceptable barrier to entry for entrepreneurship and believe that it is both unfair, and reduces the volume and quality of new businesses as many individuals with the talent, motivation and passion to start their own business are unable to. At the ideas42 Venture Studio we can’t guarantee your success, but we can reduce your risk of trying to build an amazing business, and support those entrepreneurs who don’t have access today."
          showBreadcrumbs
        >
          <DetailsCard />
        </Hero>
      </Masthead>
      <article className="standard-restricted-width">
        <section className="g-max-3 g-centered font-size-2">
          <HeadingAnchor level="2" id="the-details" text="The details" />
          <p className="font-size-3">
            Building a business is hard. Building a large, socially impactful
            business is even harder. It shouldn’t require you to risk everything
            to give it a go. Join us. Build something amazing. We don’t have all
            the answers and we won’t have it all figured out when you join us.
            But we know it takes a solid foundation to build a thriving
            technology business. We provide that foundation.
          </p>

          <HeadingAnchor
            level="3"
            id="compensation-package"
            text="Compensation package"
          />

          <List resourceList={compensationList} />
        </section>
        <section className="g-max-3 g-centered font-size-2">
          <HeadingAnchor
            level="2"
            id="technical-and-business-building-support"
            text="Technical and Business Building Support"
          />
          <p className="font-size-3">
            Full time dedicated (shared across four entrepreneurs) from the
            ideas42 Venture Studio team, including two software developers, a
            product manager and product designer. Note that these are
            specifically not advisory roles, but intended to do actual work in
            partnership with you.
          </p>
          <List resourceList={supportList} />
        </section>
        <section className="g-max-3 g-centered font-size-2">
          <HeadingAnchor
            level="2"
            id="business-building-capital"
            text="Business building capital"
          />
          <p className="font-size-3">
            There are lots of expenses that come with starting a business, not
            all of which are predictable. As an entrepreneur at the ideas42
            Venture Studio you will have access to roughly $120k to spend (with
            approval by the studio lead) for sales, marketing, legal and
            additional tech support during your 18 months at the Studio.
          </p>

          <HeadingAnchor
            level="3"
            id="ideas42-support"
            text="ideas42 support"
          />
          <List resourceList={ideas42SupportList} />

          <HeadingAnchor
            level="3"
            id="additional-support-if-desired"
            text="Additional support if desired"
          />
          <ul className="font-size-3">
            <li>
              <strong>ideas42</strong>
              <List resourceList={additionalSupportList} />
            </li>
            <li>
              The studio is funded by ideas42 through a grant from Wells Fargo,
              and if so desired, entrepreneurs at Ventures for Shared Prosperity
              will have access to teams across Wells Fargo who may serve as
              additional marketing, engineering or other support. What business
              you build, however, is entirely up to you.
            </li>
          </ul>
          <p>
            On a final note, being an entrepreneur can be lonely and a roller
            coaster of emotional highs and lows. While we can’t make it easy, we
            are here as your partner, and team. We will work to lift you up with
            encouragement, feedback, and kindness so you can bring your best
            self to the process.
          </p>
        </section>
      </article>
    </Layout>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query WhatWeProvidePageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <WhatWeProvidePage data={data} {...props} />}
  />
);
