export type Content = {
  name: string;
  description: string;
};

export const compensationList = [
  {
    name: "Salary: ",
    description: "80K annual salary for 18 months",
  },
  {
    name: "Benefits: ",
    description: "Healthcare + dependent care benefits",
  },
  {
    name: "Workspace: ",
    description:
      "Home office or remote office set-up support and supply (laptop, monitor, etc.)",
  },
  {
    name: "Equity: ",
    description: "40% stake in any business we build together",
  },
];

export const supportList = [
  {
    name: "Software Engineer and UX Designer: ",
    description:
      "Two full time software engineers/UX designers to help build product prototypes and initial working products",
  },
  {
    name: "Product Manager: ",
    description:
      "One full time individual to help define and prioritize product features, draft user stories and align your vision with short-term priorities",
  },
  {
    name: "Business Designer: ",
    description:
      "One full time individual to help evaluate market opportunities/sizes, develop business plans, pricing models, go to market strategy and business model",
  },
];

export const ideas42SupportList: Content[] = [
  {
    name: "Behavioral design: ",
    description: "Basic behavioral design training and support",
  },
  {
    name: "Legal",
    description: "Legal support from the ideas42 General Counsel",
  },
];

export const additionalSupportList: Content[] = [
  {
    name: "Focus area research: ",
    description:
      "Each entrepreneur will be provided a detailed research document and briefing on the cohort focus area developed by ideas42 research staff",
  },
  {
    name: "Behavioral Design support: ",
    description:
      "Each entrepreneur will have access to roughly two months of a full time ideas42 Behavioral Design team to do market research and behavioral analysis",
  },
  {
    name: "Marketing support: ",
    description:
      "Access to the ideas42 communications team to support marketing strategy and media outreach",
  },
  {
    name: "Convening: ",
    description:
      "For each cohort of entrepreneurs, ideas42 will organize a convening of academics, practitioners and investors to learn and discuss opportunities related to the specific focus area of that cohort",
  },
];
