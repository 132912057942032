import React from "react";
import { Link } from "gatsby";
import Arrow from "../images/graphic-arrow-btn.inline.svg";
import "./FooterBackButton.scss";

interface FooterBackButtonProps {
  to: string;
  text: string;
}

const FooterBackButton: React.FC<FooterBackButtonProps> = ({ to, text }) => (
  <div className="back-button">
    <div className="back-button__contents standard-restricted-width">
      <Link to={to} className="back-button__link">
        <span className="btn__arrow btn__arrow--reverse">
          <Arrow className="back-button__arrow" />
        </span>{" "}
        <strong className="back-button__text">{text}</strong>
      </Link>
    </div>
  </div>
);

export default FooterBackButton;
